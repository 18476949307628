import React, { useEffect, useState, forwardRef } from "react";
import styles from "./buttonGlobal.module.css"
import classnames from 'classnames';
import { IoCloseSharp } from "react-icons/io5";
import {useDisable} from "../../utlis";

// const ButtonGlobal = ({ title = "", width = "full", radius = "full", size = "medium", bgColor = "", className = "", disable = false, onClick, children, icon = "", classNameIn, delayAfterClick = false, type, lodingDisabled, ref  }) => {

  const ButtonGlobal = forwardRef(({ title = "", width = "full", radius = "full", size = "medium", bgColor = "", className = "", disable = false, onClick, children, icon = "", classNameIn, delayAfterClick = false, type, lodingDisabled }, ref) => {


  const [isDisabled, triggerDisable] = useDisable(1500);
  
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }

    if (delayAfterClick) {
      triggerDisable();
    }
    
  }


  return (
    <div
      className={classnames({
        [styles.buttonGlobalMain]: true,
        [className]: className,
        [styles[width]]: width,
        [styles.disableClass]: disable,
        [styles[size]]: size,
        [styles[radius]]: radius,
        [styles[bgColor]]: bgColor,
        // [styles[icon?.align === "right" ? icon?.align : "left"]]: icon && icon?.src,
        [styles[icon]]: icon
      })}
    >
      <button ref={ref} disabled={lodingDisabled || isDisabled} onClick={handleClick} 
      // {...(onClick ? { onClick } : {})}
      type={type}
      >
        {children ? <>{children}</> :
          <span>
            {icon == "plus" && <b className={`${classNameIn} flex -ml-3 mr-1`}>
              <img
                src={process.env.PUBLIC_URL + '/images/sidebar/plus.png'}
                alt=""
              />
            </b>}

            {icon == "close" && <b className={`flex `}>
              <IoCloseSharp />
            </b>}
            {(lodingDisabled || isDisabled) ? <span className="text-[12px]">Loading...</span> : title}
          </span>}
      </button>

    </div>
)
});


export default ButtonGlobal;

// title = "" Add title for button text,
// width = (bydefault full width is 100% ) If you want to manage width to use these propertys like half, auto and quarter
// className = If you modify button different style to use this property className={styles.abc}
// disable = use this property for disable Btn
// size = varients small, mediun (bydefault) and large 
// bgColor = (bydefault is blue) add property green, gray, border and red
// icon = have tow property icon={ src: "", align: bydefault is left || right }  Example icon={{src:lcIcon, align: "left"}}
//border-radius have three option bydefault full medium 8px and none 0px



