
import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { BASE_URL } from "../redux/constants/constants";
import Cookies from "js-cookie";
import axios from "axios";
import { useTheme, useMediaQuery } from "@mui/material";
import { useNavigate, Navigate } from 'react-router-dom';
import moment from "moment";
import 'moment-timezone';
const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken") || null);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")) || null);
 
  const logIn = async (token, data) => {
    setUserToken(token);
    setUserData(data);
  };

  const logOut = () => {
    setUserToken(null);
    setUserData(null);
    localStorage.removeItem("userToken");
    localStorage.removeItem("user");
    Cookies.remove('jwtToken');
   
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ userToken, userData, logIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export const useAuthData = () => {
  const [authList, setAuthList] = useState({});
  const [unlockList, steUnlockList] = useState({});

  useEffect(() => {
    const stored = localStorage.getItem("user");
    const unlocked = localStorage.getItem("unlock");
    setAuthList(stored ? JSON.parse(stored) : {});
    steUnlockList(unlocked ? JSON.parse(unlocked) : []);
  }, []);

  return { authList, unlockList };
};

export const useUserDetailData = () => {
  const token = Cookies.get('jwtToken');
  localStorage.setItem('token', token);
  const [userDataGlobal, setUserDataGlobal] = useState({});

  const userDetailFunc = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const bodyParameters = {
      key: "value"
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/get_profile`,
        bodyParameters,
        config
      );
      setUserDataGlobal(response?.data);
    } catch (error) {
      console.log(error);
      // setUserDataGlobal({loading: false, user: null, error: error.message});
    }
  };

  useEffect(() => {
    userDetailFunc();
  }, [token]);

  return { userDataGlobal };
};

export const clearTokenAndStorage =()=> {
  Cookies.remove("jwtToken");
  localStorage.clear();
  window.location.reload();
  window.location = ('/')
}

export const logOut = () => {
  Cookies.remove("jwtToken");
  localStorage.clear();
  window.location.reload();
  window.location = ('/')
};

export const customStyles = {
  control: (provided) => ({
    ...provided,
    height: 44,
    borderRadius: 8,
    background: "#F4F4F4",
    border: "0px solid #EBECF0",
    color: "white",
    paddingLeft: 0,
    width: "100%",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f8f8f8" : "#EBECF0",
    color: state.isFocused ? "#42526E" : "#42526E",
    cursor: "pointer",
    fontSize: 14
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: 8,
    border: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13,
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: "4px 0 0 0",
    border: 0,
    zIndex: 3,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#3265F3",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#C9C5CA",
    fontSize: 14
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: "#2A2D32",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#2A2D32",
  }),
  
  
  indicatorSeparator: () => ({ display: "none" }),
};

export const customStylesHeight = {
  control: (provided) => ({
    ...provided,
    minHeight: 44,
    borderRadius: 8,
    background: "#F4F4F4",
    border: "0px solid #EBECF0",
    color: "white",
    paddingLeft: 0,
    width: "100%",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f8f8f8" : "#EBECF0",
    color: state.isFocused ? "#42526E" : "#42526E",
    cursor: "pointer",
    fontSize: 14
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: 8,
    border: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: "4px 0 0 0",
    border: 0,
    zIndex: 3,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#3265F3",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#C9C5CA",
    fontSize: 14
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: "#2A2D32",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#2A2D32",
  }),
  
  
  indicatorSeparator: () => ({ display: "none" }),
};

export const customStylesSmall = {
  control: (provided) => ({
    ...provided,
    height: 36,
    borderRadius: 8,
    background: "#F4F4F4",
    border: "0px solid #EBECF0",
    color: "white",
    paddingLeft: 0,
    width: "100%",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f8f8f8" : "#EBECF0",
    color: state.isFocused ? "#42526E" : "#42526E",
    cursor: "pointer",
    fontSize: 14
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: 8,
    border: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: "4px 0 0 0",
    border: 0,
    zIndex: 3,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#3265F3",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#C9C5CA",
    fontSize: 14
  }),
  indicatorSeparator: () => ({ display: "none" }),
};

export const customBorderStyles = {
  control: (provided) => ({
    ...provided,
    height: 44,
    borderRadius: 8,
    background: "#F4F4F4",
    border: "1px solid #424867",
    color: "white",
    paddingLeft: 0,
    width: "100%",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f8f8f8" : "#EBECF0",
    color: state.isFocused ? "#42526E" : "#42526E",
    cursor: "pointer",
    fontSize: 14
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: 8,
    border: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: "4px 0 0 0",
    border: 0,
    zIndex: 3,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#3265F3",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#2A2D32",
    fontSize: 14
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: "#2A2D32",
    fontSize: 4
  }),
  indicatorSeparator: () => ({ display: "none" }),
};

export const customBorderSmallStyles = {
  control: (provided) => ({
    ...provided,
    height: 36,
    borderRadius: 12,
    background: "#fff",
    border: "1px solid #ddd",
    color: "white",
    paddingLeft: 0,
    width: "100%",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f8f8f8" : "#EBECF0",
    color: state.isFocused ? "#42526E" : "#42526E",
    cursor: "pointer",
    fontSize: 14
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: 8,
    border: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: "4px 0 0 0",
    border: 0,
    zIndex: 3,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#3265F3",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#2A2D32",
    fontSize: 14
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: "#2A2D32",
    fontSize: 4
  }),
  indicatorSeparator: () => ({ display: "none" }),
};

export const useResponsiveBreakpoints = () => {
  const theme = useTheme();

  const customBreakpoints = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1024, 
    xl: 1536,
  };

  const isMobileDown = useMediaQuery(theme.breakpoints.down('sm')); // Max width: 600px
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm')); // Min width: 600px
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg')); 
  const isTabletDown = useMediaQuery(theme.breakpoints.down('md')); // Max width: 960px
  const isTabletUp = useMediaQuery(theme.breakpoints.up('md')); // Min width: 960px
  const isTablet1024 = useMediaQuery(`(max-width:${customBreakpoints.lg}px)`);
  const isTablet900 = useMediaQuery(`(max-width:${customBreakpoints.md}px)`);
  const isTablet600 = useMediaQuery(`(max-width:${customBreakpoints.sm}px)`);
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl')); // Min width: 1536px

  return { isMobileDown, isMobileUp, isTabletDown, isTabletUp, isDesktop, isTablet1024, isTablet600, isTablet900 };
};


export const AuthGuard =({ children })=> {
  const { userToken, userData } = useAuth();
  const navigate = useNavigate();

  if (userToken && userData) {
    const { role_id } = userData;
    let dashboardPath = '';

    switch (role_id) {
      case 1:
        dashboardPath = '/super-admin-dashboard';
        break;
      case 2:
        dashboardPath = '/school-dashboard';
        break;
      case 3:
        dashboardPath = '/teacher-dashboard';
        break;
      case 4:
        dashboardPath = '/student-dashboard';
        break;
      default:
        dashboardPath = '/dashboard'; // Default dashboard path for unknown roles
        break;
    }

    return <Navigate to={dashboardPath} replace />;
  }
  return children;
}


export const axiosInstance = axios.create({
  baseURL: 'YOUR_API_BASE_URL', 
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('jwtToken');// Get the token from wherever you store it
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**********shorts elements *******/
export const genderOptions =[
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" }
] 

export const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const formatDateAndTimeOne = (date) => {
  // return moment.tz(date, "YYYY-MM-DD HH:mm", "Asia/Kolkata").format("YYYY-MM-DD hh:mm A");
    return moment(date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD hh:mm A");
};


export const formatDateAndTimeTwo = (date) => {
    return moment(date, "YYYY-MM-DD hh:mm A").format("YYYY-MM-DD HH:mm");
};


  export const formatDateAndTime = (date) => {
    // const formattedDate = moment.tz(date, "YYYY-MM-DD", "Asia/Kolkata").format("YYYY-MM-DD ddd");
    const formattedDate = moment(date, "YYYY-MM-DD hh:mm A").format("YYYY-MM-DD HH:mm");

    const formattedTime = moment(date).format("HH:mm");
    const dayOfWeek = moment.tz(date, "Asia/Kolkata").format("ddd");
  
    return { formattedDate, formattedTime, dayOfWeek };
  };


export const dateFunction =(data)=>{
  const dateObject = new Date(data)
  const formattedDate = dateObject.toLocaleDateString('en-IN');
  return formattedDate;
}

export const liveTrackerOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

export const boardOptions = [
  { value: "CBSE", label: "CBSE" },
  { value: "ICSE", label: "ICSE" },
  { value: "State Board", label: "State Board" },
  { value: "UP-Board", label: "UP-Board" },
  { value: "Others", label: "Others" },
];

export const gradeOptions = [
  { value: "Pre Nur", label: "Pre Nur" },
  { value: "Nur", label: "Nur" },
  { value: "LKG", label: "LKG" },
  { value: "UKG", label: "UKG" },
  { value: "1st", label: "1st" },
  { value: "2nd", label: "2nd" },
  { value: "3rd", label: "3rd" },
  { value: "4th", label: "4th" },
  { value: "5th", label: "5th" },
  { value: "6th", label: "6th" },
  { value: "7th", label: "7th" },
  { value: "8th", label: "8th" },
  { value: "9th", label: "9th" },
  { value: "10th", label: "10th" },
  { value: "11th", label: "11th" },
  { value: "12th", label: "12th" },
]

export const schoolRoleOption = [
  { value: "Owner", label: "Owner" },
  { value: "Principal", label: "Principal" },
  { value: "Admin", label: "Admin" },
  { value: "Other", label: "Other" },
];

export const paymentOptions = [
  { value: "Cash", label: "Cash" },
  { value: "Cheque", label: "Cheque" },
  { value: "DD", label: "DD" },
  { value: "NEFT", label: "NEFT" },
  { value: "RTGS", label: "RTGS" },
]


export const paymentStatusOptions = [
  { value: "Paid", label: "Paid" },
  { value: "Pending", label: "Pending" },
]

export const schoolMediumOption = [
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
  { value: "others", label: "others" },
];

export const typeOptions = [
  { value: "Leave", label: "Leave" },
  { value: "Absent", label: "Absent" },
  { value: "Present", label: "Present" },
  // { value: "Half Day", label: "Half Day" },
];

export const months = [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
];

export const years = [
  {value: 2024, label: 2024},
  {value: 2023, label: 2023},
]

export const statusListUnlock = [
  {value: "Approved", label: "Approved"},
  {value: "Pending", label: "Pending"},
  {value: "Rejected", label: "Rejected"},
  {value: "On-Hold", label: "On-Hold"}]

/**********shorts elements end *******/


export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// export const useLocalStorageWithEffect = () => {
//   const [storedValue, setStoredValue] = useState(localStorage.getItem("user"));

//   useEffect(() => {
//     const stored = localStorage.getItem("user");
//    if(stored){
//     setStoredValue(stored);
//    } 
//   }, []);

//   return {storedValue}
// };

// export const useLocalStorageWithEffect = (key) => {
//   const storedValue = localStorage.getItem(key);

//   return { storedValue };
// };


export const menuList = [
  {
    title: "Dashboard Management",
    titleName: "Dashboard",
    url: "/school-dashboard",
    subUrl: "/add",
    icon: "dash.png",
    roles: [2],
    unlock: "m_dm_11",
    done: "new"
  },
  {
    title: "Student Category",
    titleName: "Student Category",
    roles: [2],
    icon: "event.png",
    url: "/student-category",
    unlock: "m_sc_61",
    done: "new"
  },
  {
    title: "Subject Management",
    titleName: "Subject Management",
    url: "/subject-management",
    subUrl: "/add",
    icon: "student.png",
    roles: [2],
    unlock: "m_sm_32",
    done: "new"
  },

  {
    title: "Teacher Dashboard",
    titleName: "Dashboard",
    url: "/teacher-dashboard",
    subUrl: "/add",
    icon: "dash.png",
    roles: [3],
    unlock: "superadmin",
  },
  {
    title: "Class Management",
    titleName: "Class Management",
    url: "/class-management",
    subUrl: "/add",
    icon: "class.png",
    roles: [2],
    unlock: "m_cm_3",
    done: "new"
  },
  {
    title: "Teacher Management",                          
    titleName: "Teacher Management",
    url: "/teacher",
    subUrl: "/add-teacher",
    icon: "staff.png",
    roles: [2],
    unlock: "m_tm_2",
    done: "new"
  },
  {
    title: "Student Management",
    titleName: "Student Management",
    url: "/student",
    subUrl: "/add-student",
    icon: "student.png",
    roles: [2],
    unlock: "m_sm_52",
    done: "new"
  },
  {
    title: "Student Management",
    titleName: "Student Management",
    url: "/student-management",
    subUrl: "/add-student",
    icon: "student.png",
    roles: [3],
    unlock: "m_sm_52",
    done: "new"
  },

  {
    title: "Student Attendance",
    titleName: "Student Attendance",
    url: "/student-attendance",
    subUrl: "/add",
    icon: "student.png",
    roles: [3],
    unlock: "m_sa_34",
    done: "new",
    hide: true,
  },
 
  {
    title: "Notice Board",
    titleName: "Notice Board",
    url: "/notice-board",
    subUrl: "/add",
    icon: "notice.png",
    roles: [2],
    unlock: "m_nb_12",
    done: "new"
  },
  {
    title: "Notice Board",
    titleName: "Notice Board",
    url: "/notice-management",
    subUrl: "/add",
    icon: "notice.png",
    roles: [3],
    unlock: "m_nb_12",
    done: "new"
  },
  {
    title: "Super Admin",
    titleName: "Super Admin",
    url: "/super-admin-dashboard",
    subUrl: "/add",
    icon: "admin.png",
    roles: [1],
  },
  {
    title: "Plan Setting",
    titleName: "Plan Setting",
    roles: [1],
    icon: "plan.png",
    subItems: [
      { title: "Package Management",  titleName: "Package Management", url: "/package-management" },
      { title: "Module Management", titleName: "Module Management", url: "/module-management" },
    ],
  },
  {
    title: "School Management",
    titleName: "School",
    url: "/school",
    subUrl: "/add-school",
    icon: "school.png",
    roles: [1],
  },
  {
    title: "Unlock Leads",
    titleName: "Unlock Leads",
    url: "/unlock-leads",
    subUrl: "/unlock",
    icon: "unlock.png",
    roles: [1],
  },
  {
    title: "Event Management",
    titleName: "Event Management",
    url: "/news-events",
    subUrl: "/add",
    icon: "event.png",
    roles: [2],
    unlock: "m_em_8",
    done: "new"
  },
  {
    title: "Event Management",
    titleName: "Event Management",
    url: "/news-event-management",
    subUrl: "/add",
    icon: "event.png",
    roles: [3],
    unlock: "m_em_8",
    done: "new"
  },

  {
    title: "Fees Management",
    titleName: "Fees Management",
    roles: [2],
    icon: "fee.png",
    unlock: "m_fm_5",
    subItems: [
      { title: "Fee Configuration", titleName: "Fee Configuration", url: "/fee-configuration" },
      { title: "Fee Submission", titleName: "Fee Submission", url: "/fee-submission" },
    ],
    done: "new"
  },
  {
    title: "Holiday Management",
    titleName: "Holiday Management",
    url: "/holiday",
    subUrl: "/add",
    icon: "holiday.png",
    roles: [2],
    unlock: "m_hm_7",
    done: "new"
  },
  {
    title: "Holiday Management",
    titleName: "Holiday Management",
    url: "/holiday-management",
    subUrl: "/add",
    icon: "holiday.png",
    roles: [3],
    unlock: "m_hm_7",
    done: "new"
  },

  {
    title: "Transport Management",
    titleName: "Transport Management",
    url: "/transport",
    subUrl: "/add",
    icon: "transport.png",
    roles: [2],
    unlock: "m_tm_4",
    done: "new"
  },
  {
    title: "Attendance Management",
    titleName: "Attendance Management",
    roles: [2],
    icon: "admission.png",
    url: "/attendance-management",
    unlock: "m_am_6",
    done: "new"
  },
  {
    title: "Leave Management",
    titleName: "Leave Management",
    roles: [3],
    icon: "admission.png",
    url: "/leave-management",
    unlock: "m_lm_55",
    done: "new"
  },
  {
    title: "Home Assignment  ",
    titleName: "Home Assignment",
    roles: [3],
    icon: "homeassign.png",
    url: "/home-management",
    unlock: "m_ha_57",
    hide: true,
    done: "new"
  },
  {
    title: "Time Table Management",
    titleName: "Time Table Management",
    roles: [3],
    icon: "time.png",
    url: "/time-management",
    unlock: "m_ttm_60",
    hide: true,
    done: "new"
  },
  {
    title: "Schedule Management",
    titleName: "Schedule Management",
    roles: [3],
    icon: "book.png",
    url: "/book-management",
    unlock: "m_sm_56",
    hide: true,
    done: "new"
  },
  {
    title: "Academic Year",
    titleName: "Academic Year",
    roles: [2],
    icon: "academic.png",
    url: "/academic-year-list",
    unlock: "m_ay_35",
    done: "new"
  },
  {
    title: "Admission Enquiry",
    titleName: "Admission Enquiry",
    url: "/admission-enquiry",
    icon: "admission.png",
    roles: [2],
    unlock: "m_aem_10",
    done: "new"
  },
  {
    title: "Visitor Management",
    titleName: "Visitor Management",
    url: "/visitor",
    icon: "visitors.png",
    roles: [2],
    unlock: "m_vm_9",
    done: "new"
  },
  {
    title: "Exam Management",
    titleName: "Exam Management",
    url: "/exam-management",
    icon: "exam.png",
    roles: [2],
    unlock: "m_em_33",
    done: "new"
  },
  {
    title: "Academic Calendar",
    titleName: "Academic Calendar",
    roles: [2],
    icon: "academic.png",
    url: "/academic-calendar",
    unlock: "m_ac_54",
    done: "new"
  },
  
  {
    title: "Important Days",
    titleName: "Important Days",
    roles: [1],
    icon: "event.png",
    url: "/important",
    // unlock: "m_id_51",
    unlock: "superadmin",
    done: "new"
  },

  {
    title: "Important Days",
    titleName: "Important Days",
    roles: [2],
    icon: "event.png",
    url: "/important-days",
    // unlock: "m_id_51",
    unlock: "superadmin",
    done: "new"
  },
  {
    title: "Important Days",
    titleName: "Important Days",
    roles: [3],
    icon: "event.png",
    url: "/important-day",
    // unlock: "m_id_51",
    unlock: "superadmin",
    done: "new"
  },
   
  {
    title: "Signup List",
    titleName: "Signup List",
    roles: [1],
    icon: "class.png",
    url: "/signup-list",
    unlock: "superadmin",
    done: "new"
  },
  {
    title: "Support List",
    titleName: "Support List",
    roles: [1],
    icon: "fee.png",
    url: "/support-list",
    unlock: "superadmin",
    done: "new"
  },

  {
    title: "Student Dashboard",
    titleName: "Dashboard",
    url: "/student-dashboard",
    subUrl: "/add",
    icon: "dash.png",
    roles: [4],
    unlock: "m_sd_38",
  },
  {
    title: "Academic Planner",
    titleName: "Academic Planner",
    roles: [4],
    icon: "academic.png",
    url: "/academic-planner",
  },
  {
    title: "Leave Management",
    titleName: "Leave Management",
    roles: [4],
    icon: "academic.png",
    url: "/leave-management",
  }
];

export const monthsSort = [
  "Jan", "Feb", "Mar", "Apr",
  "May", "Jun", "Jul", "Aug",
  "Sep", "Oct", "Nov", "Dec"
];

export const paymentTerm = [
  {label:"Monthly", value:"Monthly" },
  {label:"Quarterly", value:"Quarterly" },
  {label:"Half-yearly", value:"Half-yearly" },
  {label:"Yearly", value:"Yearly" },
  {label:"One-time", value:"One-time" },
];

export const religionOption = [
  {label:"Hindu", value:"Hindu" },
  {label:"Muslim", value:"Muslim" },
  {label:"Christain", value:"Christain" },
  {label:"Skih", value:"Skih" },
  {label:"Buddhist", value:"Buddhist" },
  {label:"Jain", value:"Jain" },
  {label:"Other", value:"Other" },
];


export const categoryObcOption = [
  {label:"GEN", value:"GEN" },
  {label:"GEN-EWS", value:"GEN-EWS" },
  {label:"OBC", value:"OBC" },
  {label:"OBC-CL", value:"OBC-CL" },
  {label:"OBC-NCL", value:"OBC-NCL" },
  {label:"SC", value:"SC" },
  {label:"ST", value:"ST" },
  {label:"Foreigner", value:"Foreigner" },
  {label:"Other", value:"Other" },
];

export const idProofOption = [
  {label:"Aadhar Card", value:"Aadhar Card" },
  {label:"Driving License", value:"Driving License" },
  {label:"Ration Card", value:"Ration Card" },
  {label:"Voter ID", value:"Voter ID" },
  {label:"OBC-NCL", value:"OBC-NCL" },
  {label:"DL", value:"DL" },
  {label:"Other", value:"Other" },
];



/*******input field only alphabet  ****/
export const onlyAlphabet = (e, func, length = 50) => {
  const inputValue = e.target.value;
  const regex = /^[a-zA-Z][a-zA-Z\s]*$/;

  if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= length)) {
    func(e);
  }
};

export const exportDownload =(url, name)=>{
  const link = document.createElement("a");
  link.href = `${BASE_URL}/${url}`;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const backTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};


// export const onlyAlpha = (value, length = 30) => {
  // return /^(?:[a-zA-Z\s]{0,length})$/.test(value);
// };

export const onlyAlpha = (value, length = 30) => {
  return new RegExp(`^(?:[a-zA-Z\\s]{0,${length}})$`).test(value);
};


export const validateTAN = (value) => {
  const isValid = /^[A-Z]{4}[0-9]{5}[A-Z]$/.test(value);
  return isValid;
};

export const validatePAN = (value) => {
  return /^([a-zA-Z])([0-9])([a-zA-Z])?$/.test(value);
};



export const base64ToBlob = async (base64String, contentType = 'image/png') => {
  try {
    const cleanBase64String = base64String.replace(/^data:(.*;base64,)?/, '');
    const byteCharacters = atob(cleanBase64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  } catch (error) {
    console.error('Error converting base64 to blob:', error);
    throw error;
  }
};


export const useDisable = (delay = 1500) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const disable = useCallback(() => {
    setIsDisabled(true);
    const timer = setTimeout(() => {
      setIsDisabled(false);
      clearTimeout(timer);
    }, delay);
  }, [delay]);

  return [isDisabled, disable];
};


export const monthData = [
      {
          "month_id": 1,
          "name": "January",
          "created_at": "2024-08-12T17:07:32.000Z",
          "updated_at": null
      },
      {
          "month_id": 2,
          "name": "Feburary",
          "created_at": "2024-08-12T17:07:32.000Z",
          "updated_at": null
      },
      {
          "month_id": 3,
          "name": "March",
          "created_at": "2024-08-12T17:07:48.000Z",
          "updated_at": null
      },
      {
          "month_id": 4,
          "name": "April",
          "created_at": "2024-08-12T17:07:48.000Z",
          "updated_at": null
      },
      {
          "month_id": 5,
          "name": "May",
          "created_at": "2024-08-12T17:08:00.000Z",
          "updated_at": null
      },
      {
          "month_id": 6,
          "name": "June",
          "created_at": "2024-08-12T17:08:00.000Z",
          "updated_at": null
      },
      {
          "month_id": 7,
          "name": "July",
          "created_at": "2024-08-12T17:08:17.000Z",
          "updated_at": null
      },
      {
          "month_id": 8,
          "name": "August",
          "created_at": "2024-08-12T17:08:17.000Z",
          "updated_at": null
      },
      {
          "month_id": 9,
          "name": "September",
          "created_at": "2024-08-12T17:08:34.000Z",
          "updated_at": null
      },
      {
          "month_id": 10,
          "name": "October",
          "created_at": "2024-08-12T17:08:34.000Z",
          "updated_at": null
      },
      {
          "month_id": 11,
          "name": "November",
          "created_at": "2024-08-12T17:08:51.000Z",
          "updated_at": null
      },
      {
          "month_id": 12,
          "name": "December",
          "created_at": "2024-08-12T17:08:51.000Z",
          "updated_at": null
      }
  ]


  export const numberToWords = (num) => {
    const ones = [
      '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
      'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
      'seventeen', 'eighteen', 'nineteen'
    ];
    const tens = [
      '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
    ];
    const thousands = ['', 'thousand', 'million', 'billion'];
  
    const convertHundreds = (num) => {
      let str = '';
      let hundred = Math.floor(num / 100);
      let remainder = num % 100;
      
      if (hundred > 0) {
        str += ones[hundred] + ' hundred ';
      }
      
      if (remainder < 20) {
        str += ones[remainder];
      } else {
        let ten = Math.floor(remainder / 10);
        let unit = remainder % 10;
        str += tens[ten] + (unit ? '-' + ones[unit] : '');
      }
  
      return str.trim();
    };
  
    const convert = (num) => {
      if (num === 0) return 'zero';
      if (num < 0) return 'minus ' + convert(-num);
  
      let str = '';
      let thousandCounter = 0;
  
      while (num > 0) {
        let segment = num % 1000;
        if (segment !== 0) {
          str = convertHundreds(segment) + ' ' + thousands[thousandCounter] + ' ' + str;
        }
        num = Math.floor(num / 1000);
        thousandCounter++;
      }
  
      return str.trim();
    };
  
    return convert(num);
  };
  
