export const errorMessages = {
    school: {
        school_name: "School Name is required",
        school_contact: "School Contact No is required",
        signatory_name: "Signatory Name is required",
        designation: "Designation is required",
        tan_number: "TAN Number is required",
        tan_name: "TAN Name is required",
        pan_number: "PAN Number is required",
        pan_name: "PAN Name is required",
        gst_number: "GST Number is required",
        termination: "Termination Period is required",
        principal_name: "Principal Name is required",
        year_title: "Year title is required",
        board_details: "Please select a Board Details option",
        package_list: "Please select a Package list option",
        grade_option: "Please select a Grade option",
        year_start: "Year start date is required",
        year_end: "Year end date is required",
        start_date: "Start Date is required",
        end_date: "End Date is required",
        current_year: "Current year is required",
        
    },

    student_category: {
        category_name: "Category Name is required",
    },

    subject: {
        subject_name: "Subject Name is required",
    },
    class: {
        class_name: "Class Name is required",
        section_name: "Section Name is required",
    },

    student: {
        current_address: 'Current address is required',
        permanent_address: 'Permanent address is required',
        parent_name: 'Parent Name is required',
        student_relation: 'Relationship to student is required',
        parents_occupations: 'Parents occupations to student is required',
        admission_number: 'Admission Number is required',
        student_category: 'Please select an student category',
        admission_date: 'Please select admission date',
    
    },
    teacher: {
        job_title: "Job Title is required",
        title: "Please select an Title option",
        language: "Please select at least one language",
        employment_status: "Employment status is required",
        hiring_status: "Hiring date status is required",
    },

    fee: {
        feet_type: "Fee Type is Required",
        fee_late: "Fee Late is Required",
        grade: "Grade is Required",
        months: "is Required",
        payment_term: "Payment Term is Required",
        payment_reminder: "Payment reminder is Required",
        student_category: "Select an student category",
        fee_type_option: "Please select an Fee Type option",
        payment: "Payment is required",
        student_id: "Student ID is required",
        fee_name: "Fee Name is required",
        fee_amount: "Amount is required",
        batch_class: "Please select an Batch/Class option",
        frequency: "Please select an Frequency option",
        reminder_settings: "Reminder Settings is required"
    },

    transport: {
        route_number: "Route No is required",
        vehicle_type: "Vehicle Type is required",
        vehicle_number: "Vehicle No is required",
        driver_name: "Driver Name is required",
        driver_phone: "Driver Contact No is required",
        helper_name: "Helper Name is required",
        helper_phone: "Helper Contact No is required",
        live_tracking: "Live Tracker is required"
    },

    attendance: {
        batch_class: "Class is required",
        batch_class_section: "Class section is required",
        student_name: "Student Name is required",
        type: "Type is required"
    },

    admission: {
        student_phone: "Student phone is required",
        student_alternate_phone: "Student Alternate Phone is required",
        admission_class: "Admission Class is required",
    },

    visitor: {
        purpose: "Purpose is required",
        person_visited: "Person to be visited is required",
        picture: "Visitor picture is required",
        entry_time: "Entry time is required",
    },

    exam: {
        exam_name: "Exam Name is required",
    },

    profile: {
        old_password: "Old Password is required",
        new_password: "New Password is required",
        same_password: "Same password cannot be used",
        password_length: "Password requires at least one uppercase, lowercase, and number",
        confirm_password: "Confirm password is required",
        password_not_match: "Passwords do not match",
        password_len_three: "Password must be more than 3 characte"
    },

    module: {
        module_name : "Package Name is required",
        module_description : "Package description is required"
    },
    package: {
        package_name : "Module Name is required",
        package_description : "Module description is required"
    },

    signup:{
        role: "School role is required",
        state : "School state is required",
        medium : "School medium is required",
        number_student: "Number of students is required",
        fee_charges : "Fee charges is required",
        fee_charges : "Fee charges is required"
    },

    global: {
        min_length_two: "Must be 2 characters or more",
        min_length_four: "Must be 4 characters or more",
        max_length_six: "Must be 6 characters",
        max_length_ten: "Must be 10 characters",
        max_length_fifteen: "Must be 15 characters",

        name: "Name is required",
        first_name: "First Name is required",
        last_name: "Last Name is required",
        phone_digits: "Phone number must be 10 digits",
        address: "Address is required",
        email: "Email is required",
        city: "City is required",
        state: "State is required",
        pincode: "Pincode is required",
        contact: "Contact No is required",
        contact_already: "Contact number already declared",
        invalid_email: "Invalid email address",
        email_already: "Email already declared",
        password: "Password is required",
        blank_fields: "Please fill in the blank fields",
        dob: 'Please select date of birth',
        gender: 'Please select an gender option',
        marital_status: 'Please select an Marital Status option',
        title: "Title is required",
        description: "Description is required",
        date: "Date is required",
        mother_name: "Mother Name is required",
        father_name: "Father Name is required",
        pdf: "PDF file is required",
        username : "Username is required!",
        username_length: "Username must be at least 4 characters."
    },

    toast_message: {
        all_fields: "All fields must be filled out",
        token_expire: "Token expired. Please login again.",
        pdf_text: "Please select a PDF file.",
        img_text: "Please select a image file.",
        img_limit_text: "Please select an image less than 2 MB.",
        img_pdf_text: "Please select a PDF or image file.",
        csv_text: "Please select a CSV file",
        upload_text: "Please select a file to upload.",
        empty_text: "Select empty field",
        exam_info: "In a class section, you cannot add two exams with the same date.",
        fee_info: "Note: You need to configure the fee, there is no data in it.",
        subscribe_text: "Subscribe to our premium package and you’ll unlock a wealth of extra information."
    },

};
